<template>
  <b-card title="DrillDown Report">
    
                <!-- basic modal -->
            <b-modal
                id="modal-1"
                title="Deduct"
                ok-only
                ok-title="Yes"
                @ok="walletBtn"
                :ok-disabled="ok_btn_prop"
                :hide-footer="alertMessage == 1"
            >
                <b-card-text>
                    <span v-if="alertMessage == 0">
                        Are you sure you want to make adjustments in wallet against CN {{focusedCn}}?
                    </span>
                    
                    <span v-else-if="alertMessage == 1">
                        <span v-if="returnMessageStatus == 0">
                            <b-alert
                            variant="success"
                            show
                            >
                            <div class="alert-body">
                                <span>{{returnMessage}}</span>
                            </div>
                            </b-alert>
                        </span>
                        <span v-else-if="returnMessageStatus == 1">
                            <b-alert
                            variant="danger"
                            show
                            >
                            <div class="alert-body">
                                <span>{{returnMessage}}</span>
                            </div>
                            </b-alert>
                        </span>
                    </span>
                </b-card-text>
            </b-modal>

            <b-modal
                id="modal-2"
                title="Deduct"
                ok-only
                ok-title="Yes"
                @ok="codBtn"
                :ok-disabled="ok_btn_prop"
                :hide-footer="alertMessage == 1"
            >
                <b-card-text>
                    <span v-if="alertMessage == 0">
                        Are you sure you want to make adjustments in COD against CN {{focusedCn}}?
                    </span>
                    
                    <span v-else-if="alertMessage == 1">
                        <span v-if="returnMessageStatus == 0">
                            <b-alert
                            variant="success"
                            show
                            >
                            <div class="alert-body">
                                <span>{{returnMessage}}</span>
                            </div>
                            </b-alert>
                        </span>
                        <span v-else-if="returnMessageStatus == 1">
                            <b-alert
                            variant="danger"
                            show
                            >
                            <div class="alert-body">
                                <span>{{returnMessage}}</span>
                            </div>
                            </b-alert>
                        </span>
                    </span>
                </b-card-text>
            </b-modal>

    <!-- table -->
        <vue-good-table
          ref="discrepancy-report"
          mode="remote"
          :columns="columns"
          :rows="rows"
          :isLoading="isLoadingProp" 
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm
          }"
          :totalRows="totalRecords"
          @on-column-filter="onColumnFilter"
          :pagination-options="{
            enabled: true,
          }"
          @on-sort-change="onSortChange"
        >
        <div slot="loadingContent">
          <b-spinner
            ref="spinner_one"
            variant="primary"
            class="mr-1"
            type="grow"
            style="width: 4rem; height: 4rem;"
          />
        </div>
        <div slot="table-actions">
        <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="greet(allData)"
            >
            Download CSV
        </b-button>
        
        <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
            v-on:click="reconciled"
            >
            Mark as Reconciled
        </b-button> -->
      </div>
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Name -->
            <!-- <span
              v-if="props.column.field === 'fullNamae'"
              class="text-nowrap"
            >
              <b-avatar
                :src="props.row.avatar"
                class="mx-1"
              />
              <span class="text-nowrap">{{ props.row.fullName }}</span>
            </span> -->

            <!-- Column: Status -->
         
            

            <!-- Column: Action -->
            <span v-if="props.column.field === 'action'">
              <span v-if="props.row.payment_status == 3">
                <span v-if="props.row.seller_wallet_adjustment == 1">
                    <b-badge variant="light-success">
                        Adjusted though Wallet
                    </b-badge>
                </span>
                <span v-else-if="props.row.seller_cod_adjustment == 1">
                    <b-badge variant="light-success">
                        Adjusted though COD
                    </b-badge>
                </span>
              </span>
              <span v-else>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    v-b-modal.modal-1
                    v-on:click="adjustBtn(props.row.tracking_number,props.row.shipment_id)"
                    >
                    Wallet
                </b-button>
               
                <p> </p>
                
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="mr-1"
                    v-b-modal.modal-2
                    v-on:click="adjustBtn(props.row.tracking_number,props.row.shipment_id)"
                    >
                    COD
                </b-button>
              </span>
            </span>

            

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">

              <!-- page length -->
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pages"
                  class="mx-1"
                  @input="handlePageChange"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @change="handleChangePage"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>

   

    
    
  </b-card>
</template>
<script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
<script type="text/javascript" src="libs/FileSaver/FileSaver.min.js"></script>
<script type="text/javascript" src="libs/js-xlsx/xlsx.core.min.js"></script>
<script type="text/javascript" src="tableExport.min.js"></script>
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown,BCard,BButton,BTabs,BTab,BCardText,BAlert,BSpinner,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import pages from '@/router/routes/pages'
import Ripple from 'vue-ripple-directive'
import reportStore from '@/store'

export default {
  components: {
    VueGoodTable,
    BCard,
    BSpinner,
    BAlert,
    BCardText,
    BTab,
    BTabs,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BButton,

  },
  directives: {
    Ripple,
  },
  data() {
    return {
      tabIndex: 0,
      mainTopic:null,
      subtopic:null,
      courierTopic:null,
      allData:null,
      alertMessage:0,
      returnMessageStatus:0,
      returnMessage:null,
      focusedCn:null,
      ok_btn_prop:false,
      shipmentId:null,
      isLoadingProp:true,
      log: [],
      pageLength: 10,
      totalRecords:0,
      dir: false,
      pages: ['10','20','50','100'],
      columns: [
        {
          label: 'Seller ID',
          field: 'seller_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Order ID',
          field: 'marketplace_reference_id',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'CN #',
          field: 'tracking_number',
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Unity Weight',
          field: 'unity_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Weight',
          field: 'actual_weight',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Tariff Weight Charges',
          field: 'unity_weight_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Weight Charges',
          field: 'actual_weight_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Tariff Cash Handling',
          field: 'unity_cash_handling_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Cash Handling',
          field: 'actual_cash_handling_charges',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Tariff Fuel Surcharge',
          field: 'unity_fuel_surcharge',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Fuel Surcharge',
          field: 'actual_fuel_surcharge',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Tariff Sales Tax',
          field: 'unity_sale_tax_for_courier',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Sales Tax',
          field: 'actual_sale_tax_for_courier',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Tariff Total Courier Charges',
          field: 'unity_total_courier_charges_with_tax',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Actual Total Courier Charges',
          field: 'actual_total_courier_charges_with_tax',
          formatFn:this.formatCharges,
          filterOptions: {
            styleClass: 'class1', // class to be added to the parent th element
            enabled: true, // enable filter for this column
            placeholder: 'Filter This Thing', // placeholder for filter input
            filterValue: '', // initial populated value for this filter
            filterDropdownItems: [], // dropdown (with selected values) instead of text input
            filterFn: this.columnFilterFn, //custom filter function that
            trigger: 'enter', //only trigger on enter not on keyup 
          },
        },
        {
          label: 'Discrepancy',
          field: 'discrepancy',
          formatFn:this.formatCharges,
          sortable: false,
        },
        {
          label: 'Adjustment',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      serverParams: {
        // a map of column filters example: {name: 'john', age: '20'}
        columnFilters: {
        },
        sort: {
          field: '', // example: 'name'
          type: '', // 'asc' or 'desc'
        },
        page: 1, // what page I want to show
        perPage: 10, // how many items I'm showing per page
      },
      searchTerm: '',
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    
    this.$http.post('discrepancy-report',{
        data:this.serverParams,
    })
    .then(res => { this.rows = res.data.data;
        this.totalRecords = res.data.totalRecords;
        this.updateAllData(res.data.data);
        this.isLoadingProp = false;
    })

  },

    mounted() {
        this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
            this.returnMessage = null;
            console.log('Modal is about to be shown', bvEvent, modalId)
        })
    },
  
  methods: {
    formatCharges:function(data){
      if(data != null){
        return data.toFixed(2);
      }
    },
    updateAllData(data){
      this.allData = null;
      this.allData = data;
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    // handleSearch(searching) {
    //   this.log.push(`The user searched for: ${searching}`)
    // },
    handleChangePage(page) {
      this.updateParams({page: page});
      this.loadItems();
    },
    handlePageChange(active) {
      this.updateParams({perPage: active});
      this.loadItems();
    },
    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },
    onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
    },
    walletBtn: function(bvModalEvt){
      this.isLoadingProp = true;
      this.ok_btn_prop = true;
        bvModalEvt.preventDefault();
        this.$http.post('discrepancy-report/adjust-wallet',{
            data:this.shipmentId,
        }).then(response => {
          this.afterSubmit(response.data.message,response.data.error);
        });
          
    },
    afterSubmit(message,error){
        this.alertMessage = 1;
        this.returnMessageStatus = error;
        this.returnMessage = message;
        this.loadItems();
    },

    codBtn: function(bvModalEvt){
      this.isLoadingProp = true;
      this.ok_btn_prop = true;
        bvModalEvt.preventDefault();
        this.$http.post('discrepancy-report/adjust-cod',{
            data:this.shipmentId,
        }).then(response => {
          this.afterSubmit(response.data.message,response.data.error);
        });
    },
    
    loadItems() {
        this.isLoadingProp = true;
        this.$http.post('discrepancy-report',{
            data:this.serverParams,
        }).then(response => {
          //  this.totalRecords = response.totalRecords;
          this.totalRecords = response.data.totalRecords;
          this.rows = response.data.data;
          this.updateAllData(response.data.data);
          this.isLoadingProp = false;
        });

    },

    adjustBtn(cn,id){
        this.ok_btn_prop = false;

        this.alertMessage = 0;
        this.returnMessageStatus = 0;
        this.returnMessage = null;

        this.focusedCn = cn;
        this.shipmentId = id;
    },

    greet: function (arrData) {
      let csvContent = "data:text/csv;charset=utf-8,";
      csvContent += [
        Object.keys(arrData[0]).join(";"),
        ...arrData.map(item => Object.values(item).join(";"))
      ]
        .join("\n")
        .replace(/(^\[)|(\]$)/gm, "");

      const data = encodeURI(csvContent);
      const link = document.createElement("a");
      link.setAttribute("href", data);
      link.setAttribute("download", "export.csv");
      link.click();
      // $('.vgt-table bordered').tableExport({type:'csv'});
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>